<template>
	<div>
		<PopupModal header-icon :header-text="'Keep me updated'" @close="close">
			<form v-if="!success" @submit.prevent="validateAndSendRegistration">
				<label>
					Your name
					<span class="sb_input_required">*</span>
					<input v-model="registration.name" type="text" placeholder="Your name" required />
				</label>
				<label>
					Your e-mail
					<span class="sb_input_required">*</span>
					<input v-model="registration.email" type="email" placeholder="Your email" required />
				</label>
				<label>
					Your company
					<input v-model="registration.company" type="text" placeholder="Your company" />
				</label>
				<label>
					Your Country
					<span class="sb_input_required">*</span>
					<select v-model="registration.country" required>
						<option :value="null" selected disabled>Select your country</option>
						<option value="AF">Afghanistan</option>
						<option value="AL">Albania</option>
						<option value="DZ">Algeria</option>
						<option value="AO">Angola</option>
						<option value="AR">Argentina</option>
						<option value="AM">Armenia</option>
						<option value="AU">Australia</option>
						<option value="AT">Austria</option>
						<option value="AZ">Azerbaijan</option>
						<option value="BD">Bangladesh</option>
						<option value="BE">Belgium</option>
						<option value="BZ">Belize</option>
						<option value="BJ">Benin</option>
						<option value="BO">Bolivia</option>
						<option value="BA">Bosnia-Herzegovina</option>
						<option value="BR">Brazil</option>
						<option value="BN">Brunei</option>
						<option value="BG">Bulgaria</option>
						<option value="KH">Cambodia</option>
						<option value="CM">Cameroon</option>
						<option value="CA">Canada</option>
						<option value="CF">Central African Republic</option>
						<option value="CL">Chile</option>
						<option value="CN">China</option>
						<option value="CO">Colombia</option>
						<option value="CR">Costa Rica</option>
						<option value="HR">Croatia</option>
						<option value="CU">Cuba</option>
						<option value="CY">Cyprus</option>
						<option value="CZ">Czech Republic</option>
						<option value="DK">Denmark</option>
						<option value="EC">Ecuador</option>
						<option value="EG">Egypt</option>
						<option value="SV">El Salvador</option>
						<option value="EE">Estonia</option>
						<option value="ET">Ethiopia</option>
						<option value="FI">Finland</option>
						<option value="FR">France</option>
						<option value="GA">Gabon</option>
						<option value="GM">Gambia</option>
						<option value="GE">Georgia</option>
						<option value="DE">Germany</option>
						<option value="GH">Ghana</option>
						<option value="GI">Gibraltar</option>
						<option value="GR">Greece</option>
						<option value="GT">Guatemala</option>
						<option value="HN">Honduras</option>
						<option value="HK">Hong Kong</option>
						<option value="HU">Hungary</option>
						<option value="IS">Iceland</option>
						<option value="IN">India</option>
						<option value="ID">Indonesia</option>
						<option value="IR">Iran</option>
						<option value="IQ">Iraq</option>
						<option value="IE">Ireland</option>
						<option value="IL">Israel</option>
						<option value="IT">Italy</option>
						<option value="JP">Japan</option>
						<option value="JO">Jordan</option>
						<option value="KZ">Kazakhstan</option>
						<option value="KE">Kenya</option>
						<option value="KW">Kuwait</option>
						<option value="LV">Latvia</option>
						<option value="LB">Lebanon</option>
						<option value="LR">Liberia</option>
						<option value="LY">Libya</option>
						<option value="LT">Lithuania</option>
						<option value="LU">Luxembourg</option>
						<option value="MK">Macedonia</option>
						<option value="MY">Malaysia</option>
						<option value="ML">Mali</option>
						<option value="MT">Malta</option>
						<option value="MX">Mexico</option>
						<option value="MD">Moldova</option>
						<option value="MC">Monaco</option>
						<option value="MN">Mongolia</option>
						<option value="MA">Morocco</option>
						<option value="MZ">Mozambique</option>
						<option value="MM">Myanmar/Burma</option>
						<option value="NA">Namibia</option>
						<option value="NP">Nepal</option>
						<option value="NL">Netherlands</option>
						<option value="AN">Netherlands Antilles</option>
						<option value="NZ">New Zealand</option>
						<option value="NI">Nicaragua</option>
						<option value="NG">Nigeria</option>
						<option value="NO">Norway</option>
						<option value="OM">Oman</option>
						<option value="PK">Pakistan</option>
						<option value="PA">Panama</option>
						<option value="PY">Paraguay</option>
						<option value="PE">Peru</option>
						<option value="PH">Philippines</option>
						<option value="PL">Poland</option>
						<option value="PT">Portugal</option>
						<option value="PR">Puerto Rico</option>
						<option value="QA">Qatar</option>
						<option value="RO">Romania</option>
						<option value="RU">Russia</option>
						<option value="RW">Rwanda</option>
						<option value="SA">Saudi Arabia</option>
						<option value="SN">Senegal</option>
						<option value="RS">Serbia Montenegro</option>
						<option value="SG">Singapore</option>
						<option value="SK">Slovak Republic</option>
						<option value="SI">Slovenia</option>
						<option value="SO">Somalia</option>
						<option value="ZA">South Africa</option>
						<option value="KR">South Korea</option>
						<option value="ES">Spain</option>
						<option value="LK">Sri Lanka</option>
						<option value="SD">Sudan</option>
						<option value="SR">Suriname</option>
						<option value="SE">Sweden</option>
						<option value="CH">Switzerland</option>
						<option value="SY">Syria</option>
						<option value="TW">Taiwan</option>
						<option value="TZ">Tanzania</option>
						<option value="TH">Thailand</option>
						<option value="TT">Trinidad and Tobago</option>
						<option value="TN">Tunisia</option>
						<option value="TR">Turkey</option>
						<option value="TM">Turkmenistan</option>
						<option value="UG">Uganda</option>
						<option value="UA">Ukraine</option>
						<option value="AE">United Arab Emirates</option>
						<option value="GB">United Kingdom</option>
						<option value="UY">Uruguay</option>
						<option value="US">USA</option>
						<option value="UZ">Uzbekistan</option>
						<option value="VE">Venezuela</option>
						<option value="VN">Vietnam</option>
						<option value="YE">Yemen</option>
						<option value="ZM">Zambia</option>
						<option value="ZW">Zimbabwe</option>
					</select>
				</label>
				<label>
					Industries
					<span class="sb_input_required">*</span>
					<select v-model="registration.foodIngredientsType" required>
						<option :value="null" selected disabled>Please select an option</option>
						<option
							v-for="ingredientOption in foodIngredientsOptions"
							:key="ingredientOption.slug"
							:value="ingredientOption.slug"
						>
							{{ ingredientOption.text }}
						</option>
					</select>
				</label>
				<label>
					Applications
					<span class="sb_input_required">*</span>
					<select v-model="registration.foodType" required>
						<option :value="null" selected disabled>Please select an option</option>
						<option v-for="foodType in foodTypes" :key="foodType.slug" :value="foodType.slug">
							{{ foodType.text }}
						</option>
					</select>
				</label>
				<div v-show="error" class="s_error">
					<p>{{ error }}</p>
				</div>
				<button
					class="sb_button v_has-icon-right v_icon-faded v_brand-primary-lighter v_expand-on-small"
					type="submit"
				>
					<ButtonObject icon="icon_ui_caret-right"> Send </ButtonObject>
				</button>
			</form>
			<div v-if="success">
				<hr />
				<p>
					Thank you for signing up for the Corbion®
					<em>Listeria</em> Control Model update.
				</p>
				<p>We will keep you updated on the e-mail address you've provided.</p>
				<hr />
				<button class="sb_button v_brand-primary-lighter v_expand-on-small" @click="close">
					<ButtonObject>Close</ButtonObject>
				</button>
			</div>
			<div v-if="loading" class="sb_modal_loader">
				<Loader />
			</div>
		</PopupModal>
	</div>
</template>

<script lang="ts">
import { mapActions } from "vuex";
import Loader from "@/components/objects/loader.vue";
import PopupModal from "@/components/objects/popup-modal.vue";

export default {
	name: "PopupModalKeepMeUpdated",
	components: {
		PopupModal,
		Loader,
	},
	emits: { close: null },
	data() {
		return {
			error: null as string | null,
			success: false,
			loading: false,
			registration: {
				email: null,
				name: null,
				company: null,
				country: null,
				foodIngredientsType: null,
				foodType: null,
			},
			oRegisterError: {
				name: "Please enter a name",
				email: "Check your email address",
				country: "Please select a country",
				company: "Please enter a company name",
				foodIngredientsType: "Please select Food Ingredients",
				foodType: "Please select a food type",
			},
			foodIngredientsOptions: [
				{
					slug: "FoodIngredients",
					text: "Food ingredients",
				},
				{
					slug: "Bioplastics",
					text: "Bioplastics",
				},
				{
					slug: "HomeCare",
					text: "Home Care",
				},
				{
					slug: "PersonalCare",
					text: "Personal Care",
				},
				{
					slug: "Pharma",
					text: "Pharma",
				},
				{
					slug: "AnimalHealth",
					text: "Animal Health",
				},
				{
					slug: "Biomaterials",
					text: "Biomaterials",
				},
				{
					slug: "PracCompany",
					text: "Purac Company",
				},
			],
			foodTypes: [
				{
					slug: "MeatPoultryAndSeafood",
					text: "Meat, Poultry & Seafood",
				},
				{
					slug: "Beverages",
					text: "Beverages",
				},
				{
					slug: "Bakery",
					text: "Bakery",
				},
				{
					slug: "RefrigeratedFoods",
					text: "Refrigerated Foods",
				},
				{
					slug: "Confectionery",
					text: "Confectionery",
				},
				{
					slug: "Dairy",
					text: "Dairy",
				},
				{
					slug: "FruitAndVegetables",
					text: "Fruit & Vegetables",
				},
				{
					slug: "MineralSupplements",
					text: "Mineral Supplements",
				},
				{
					slug: "SaucesDressingsAndCondiments",
					text: "Sauces Dressings & Condiments",
				},
				{
					slug: "SavorySnacks",
					text: "Savory Snacks",
				},
			],
		};
	},
	methods: {
		...mapActions("notification", ["keepUserUpdated"]),
		close() {
			if (this.success) {
				this.reset();
			}
			this.$emit("close");
		},
		reset() {
			for (const key of Object.keys(this.registration)) {
				const typedKey = key as keyof typeof this.registration;

				this.registration[typedKey] = null;
				this.success = false;
			}
		},
		async validateAndSendRegistration() {
			for (const key of Object.keys(this.registration)) {
				const typedKey = key as keyof typeof this.registration;

				if (!this.registration[typedKey]) {
					this.error = this.oRegisterError[typedKey];
					return false;
				}
			}
			this.error = null;

			this.loading = true;
			try {
				await this.keepUserUpdated({
					...this.registration,
				});
			} catch (error: TODO) {
				this.loading = false;
				if (error.response && error.response.data === "Invalid email") {
					throw new Error("e-mail address already registered");
				}
				throw new Error(
					(error.response.data && JSON.stringify(error.response.data)) || error.message,
				);
			}
			this.loading = false;
			this.success = true;

			return true;
		},
	},
};
</script>

<style lang="scss"></style>
