/**
 * @param {string | number} number
 * @param {number} decimalPlaces
 * @returns
 */
export function toFixedAtMost(number, decimalPlaces) {
	if (typeof number !== "string" && typeof number !== "number") {
		return undefined;
	}

	const parsedNumber = typeof number === "string" ? Number(number) : number;
	if (isNaN(parsedNumber)) {
		return undefined;
	}

	return Number(parsedNumber?.toFixed(decimalPlaces));
}
