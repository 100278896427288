import axios from "axios";

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		async calculator(context, payload) {
			const { data } = await axios.post("/listeria-control-models", payload);
			return data;
		},
		async plot(context, { modelResultId }) {
			const res = await axios.get(`/listeria-control-models/${modelResultId}/plot`, {
				responseType: "blob",
			});
			return res;
		},
		async pdf(context, modelResultId) {
			const { data } = await axios.get(`/Calculator/pdf/${modelResultId}`, {
				responseType: "blob",
			});
			return data;
		},
		async parameterRanges(context, { foodType, region }) {
			const { data } = await axios.get("/Calculator/parameter-ranges", {
				params: {
					FoodType: foodType,
					Region: region,
				},
			});
			return data;
		},
		async products(context, { foodType, region }) {
			const { data } = await axios.get("/Calculator/products", {
				params: {
					FoodType: foodType,
					Region: region,
				},
			});
			return data;
		},
		async report(context, modelResultId) {
			const { data } = await axios.get(`/Calculator/report/${modelResultId}`, {
				responseType: "blob",
			});
			console.info("report", data);
			return data;
		},
		async result(context, { modelResultId }) {
			const { data } = await axios.get(`/listeria-control-models/${modelResultId}`);
			return data;
		},
	},
};
