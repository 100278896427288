/**
 * Converts a step attribute string to the number of decimal places it represents.
 *
 * @example
 * 	// Returns 1 because "0.01" has one decimal place.
 * 	const result = stepAttributeToDecimals("0.01");
 *
 * @param {string} step - The step attribute as a string, e.g., "0.01".
 * @returns {number} - The number of decimal places in the step attribute. Returns 0 if there are no
 *   decimal places.
 * @throws {TypeError} - Throws a TypeError if the input is not a string.
 */
export function stepAttributeToDecimals(step: string): number {
	const match = step.match(/\.(\d+)/);
	return match ? match[1].length : 0;
}
