<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import { Separator, type SeparatorProps } from "radix-vue";
import { cx } from "@/lib/style/cva.config";

const props = withDefaults(
	defineProps<SeparatorProps & { class?: HTMLAttributes["class"]; type?: "rounded" | "sharp" }>(),
	{
		class: undefined,
		type: "rounded",
	},
);

const delegatedProps = computed(() => {
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<Separator
		v-bind="delegatedProps"
		:class="
			cx(
				'shrink-0 bg-neutral-200 dark:bg-neutral-800',
				props.type === 'rounded' ? 'rounded-[50%]' : '',
				props.orientation === 'vertical' ? 'h-full w-0.5' : 'h-0.5 w-full',
				props.class,
			)
		"
	/>
</template>
