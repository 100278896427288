<template>
	<div
		:class="cx('sb_app', !!$route.meta.showFixedActionBar && 'pb-16')"
		:data-compact="($store.state as TODO).global.exportMode ? 'enabled' : 'disabled'"
		:data-results="resultsShown ? 'shown' : 'hidden'"
	>
		<ErrorBoundary
			:stop-propagation="true"
			class="sb_app_error-boundary flex flex-1 flex-col"
			@error-captured="handleBoundaryError"
		>
			<Header v-show="!$route.meta.hideHeader" />
			<RouterView
				class="sb_main-container flex flex-1 flex-col"
				:class="{ 's_expert-mode': ($store.state as TODO).global.expertMode }"
			/>
			<Symbols />
			<Footer v-show="!$route.meta.hideFooter" class="results:hidden" />

			<PopupModal v-if="axiosError" :header-text="'Error'" @close="axiosError = null">
				<p>{{ axiosError }}</p>
			</PopupModal>
		</ErrorBoundary>
	</div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import ErrorBoundary from "@/components/objects/error-boundary.vue";
import PopupModal from "@/components/objects/popup-modal.vue";
import Symbols from "@/components/svg-symbols.vue";
import { cx } from "@/lib/style/cva.config";
import { useMiscStore } from "@/store/misc";

const axiosError = ref(null);
const { resultsShown } = storeToRefs(useMiscStore());

function handleBoundaryError(error: TODO) {
	console.error("boundary error", error);
	axiosError.value = error.err;
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.sb_main-container {
	min-height: calc(100vh - 300px);

	&.s_expert-mode {
		min-height: calc(100vh - 158px);
	}
}
</style>
