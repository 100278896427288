export const logInfo = () => {
	console.group(
		"%cApplication information",
		"font-family: monospace; font-size: 14px; font-weight: bold;",
	);
	console.table({
		Name: __APPLICATION_NAME__,
		Version: __APPLICATION_VERSION__,
		Environment: __APPLICATION_ENVIRONMENT__,
		"Git hash": __APPLICATION_COMMIT_HASH__,
		"Build date": __APPLICATION_TIME_OF_BUILD__,
	});
	console.groupEnd();
};
