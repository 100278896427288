import axios from "axios";

export default {
	namespaced: true,
	state: {
		allowDebugging: false,
		allowPureProducts: false,
		maximumInputProducts: false,
		type: "NotSet",
	},
	getters: {},
	mutations: {
		clearPermissions(state: TODO) {
			state.allowDebugging = false;
			state.allowPureProducts = false;
			state.maximumInputProducts = false;
			state.type = "NotSet";
		},
		setPermission(
			state: TODO,
			{ allowDebugging, allowPureProducts, maximumInputProducts, type }: TODO,
		) {
			state.allowDebugging = allowDebugging;
			state.allowPureProducts = allowPureProducts;
			state.maximumInputProducts = maximumInputProducts;
			state.type = type;
		},
	},
	actions: {
		async permission({ commit }: TODO) {
			commit("clearPermissions");
			if (!axios.defaults.headers.common["access-token"]) {
				return false;
			}
			const { data } = await axios.get("/Permission");
			console.info("permission", {
				data,
			});
			commit("setPermission", data);
			return data;
		},
	},
};
