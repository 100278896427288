<template>
	<!-- eslint-disable max-len -->
	<button class="sb_button v_info u_no-margin">
		<slot />

		<svg viewBox="0 0 500 500" width="100%" height="100%">
			<path
				d="M467,250c0,119.85-97.15,217-217,217S33,369.85,33,250,130.15,33,250,33A217,217,0,0,1,467,250Z"
				class="sb_info_cirle"
			/>
			<path
				d="M250,182.35c16.62,0,30.38-13.37,30.38-30s-13.76-30-30.38-30a30.53,30.53,0,0,0-21.42,8.76,29.63,29.63,0,0,0-9,21.21C219.62,169,233.38,182.35,250,182.35Z"
				class="sb_info_i_cirle"
			/>
			<path
				d="M250,224.25c-16.62,0-30.38,13.44-30.38,28.82V361.65c0,15.39,13.76,27.94,30.38,27.94s30.38-12.56,30.38-27.94V253.07C280.38,237.67,266.62,224.25,250,224.25Z"
				class="sb_info_i_base"
			/>
		</svg>
	</button>
	<!-- eslint-enable max-len -->
</template>

<script>
export default {
	name: "ButtonInfo",
	data() {
		return {};
	},
};
</script>

<style lang="scss">
.sb_button {
	&.v_info {
		padding: 0 0.2rem;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		svg {
			width: 1.15em;
			height: 1.15em;
			transform: translateY(-3px);
		}
	}
}
</style>
