<template>
	<div class="sb_limit-input" :class="outerClass">
		<!-- eslint-disable-next-line -->
		<VDropdown
			v-if="attrs['show-warning'] && (outOfBounds || valueTooHigh || valueTooLow)"
			trigger="click"
			class="sb_limit-input_popover"
			:data-warning-location="warningLocation"
		>
			<button class="sb_limit-input_exclamation tooltip-target">
				<SvgIcon :icon-id="'icon_ui_exclamation'" />
			</button>

			<template #popper>
				<button v-close-popper class="tooltip-btn-close">
					<SvgIcon :icon-id="'icon_ui_close'" />
				</button>

				<p v-for="(message, index) in errorMessages" :key="index">
					{{ message }}
				</p>

				<button
					v-close-popper
					class="sb_button v_has-icon-right v_icon-faded v_ghost_brand-primary-lighter v_expand-on-small"
					@click="$emit('update:modelValue', lowestMinumumValue)"
				>
					<ButtonObject icon="icon_ui_caret-right">
						set min ({{ lowestMinumumValue }})
					</ButtonObject>
				</button>

				<button
					v-close-popper
					class="sb_button v_has-icon-right v_icon-faded v_ghost_brand-primary-lighter v_expand-on-small"
					@click="$emit('update:modelValue', highestMaximumValue)"
				>
					<ButtonObject icon="icon_ui_caret-right">
						set max ({{ highestMaximumValue }})
					</ButtonObject>
				</button>
			</template>
			<!-- eslint-disable-next-line -->
		</VDropdown>

		<input
			:class="{
				's_was-limited': wasLimited,
				's_out-of-bounds': outOfBounds || valueTooHigh || valueTooLow,
			}"
			:value="roundedNumbersOnly ? roundValue : modelValue"
			v-bind="attrs"
			data-lpignore="true"
			:style="{
				height: compact ? '28px' : '32px',
				padding: compact ? '0 0.75rem 0.25rem 0.75rem' : '0 0.75rem 0.75rem 0.75rem',
				...(width ? { width: `${width}px` } : {}),
			}"
			@focusout="input"
			@change="input"
			@focus="wasLimited = false"
		/>
	</div>
</template>

<script lang="ts">
import { stepAttributeToDecimals } from "@/lib/stepAttributeToDecimals";
import { toFixedAtMost } from "@/lib/toFixedAtMost";

export default {
	inheritAttrs: false,
	props: {
		modelValue: {
			type: [String, Number],
			default: "",
		},
		minOverride: {
			type: Number,
			default: null,
		},
		width: {
			type: Number,
			default: null,
		},
		compact: {
			type: Boolean,
			default: false,
		},
		roundedNumbersOnly: {
			type: Boolean,
			default: false,
		},
		warningLocation: {
			type: String,
			default: "left-center",
		},

		/** Useful for when two inputs where one needs to be higher than the other. */
		mustBeHigherThan: {
			type: Number,
			default: null,
		},

		/** Useful for when two inputs where one needs to be higher than or equal to the other. */
		mustBeHigherThanOrEqualTo: {
			type: Number,
			default: null,
		},

		/** Useful for when two inputs where one needs to be lower than the other. */
		mustBeLowerThan: {
			type: Number,
			default: null,
		},

		/** Used for deciding suggestions for min/max values. */
		maxType: {
			type: String,
			default: "max",
		},

		diff: {
			type: String,
			default: "0.1",
		},

		outerClass: {
			type: String,
			default: "",
		},
	},
	emits: { "update:modelValue": String, "update:outOfBounds": null },
	data() {
		return {
			wasLimited: false,
			internalValue: this.modelValue,
		};
	},
	computed: {
		attrs() {
			const { input, ...attrs } = this.$attrs;
			return attrs;
		},

		/** See https://stijlbreuk.eu.teamwork.com/app/tasks/3388876 */
		lowestMinumumValue() {
			if (!this.mustBeHigherThan) return Number(this.attrs.min);
			const func = this.maxType === "max" ? Math.min : Math.max;
			const opt1 = Number(this.mustBeHigherThan) + Number(this.diff);
			const opt2 = this.minOverride !== null ? this.minOverride : Number(this.attrs.min);
			return func(opt1, opt2).toFixed(1);
		},

		/** https://stijlbreuk.eu.teamwork.com/app/tasks/3388876 */
		highestMaximumValue() {
			if (!this.mustBeLowerThan) return Number(this.attrs.max);
			const func = this.maxType === "max" ? Math.min : Math.max;
			const opt1 = this.mustBeLowerThan - Number(this.diff);
			const opt2 = Number(this.attrs.max);
			return func(opt1, opt2).toFixed(1);
		},

		valueTooHigh() {
			return !!this.mustBeLowerThan && Number(this.modelValue) >= this.mustBeLowerThan;
		},

		valueTooLow() {
			if (!!this.mustBeHigherThan && !!this.mustBeHigherThanOrEqualTo) {
				throw new Error("You can only use one of mustBeHigherThan and mustBeHigherThanOrEqualTo");
			}

			if (this.mustBeHigherThan) {
				return Number(this.modelValue) <= this.mustBeHigherThan;
			}

			if (this.mustBeHigherThanOrEqualTo) {
				return Number(this.modelValue) < this.mustBeHigherThanOrEqualTo;
			}

			return false;
		},

		outOfBounds() {
			return !(
				Number(this.modelValue) >= Number(this.attrs.min) &&
				Number(this.modelValue) <= Number(this.attrs.max)
			);
		},

		errorMessages() {
			if (this.outOfBounds) {
				return ["Your value is out of bounds", `${this.attrs.description}`];
			}
			if (this.valueTooHigh || this.valueTooLow) {
				return [
					`Please specify a value between ${this.lowestMinumumValue} and ${this.highestMaximumValue}.`,
				];
			}

			return "";
		},

		roundValue() {
			return Math.round(Number(this.modelValue));
		},
	},
	watch: {
		lowestMinimumValue() {
			this.notifyOutOfBounds();
		},
		highestMaximumValue() {
			this.notifyOutOfBounds();
		},
		valueTooHigh() {
			this.notifyOutOfBounds();
		},
		valueTooLow() {
			this.notifyOutOfBounds();
		},
		mustBeHigherThan() {
			this.notifyOutOfBounds();
		},
		mustBeLowerThan() {
			this.notifyOutOfBounds();
		},

		modelValue: {
			handler() {
				this.notifyOutOfBounds();
			},
			immediate: true,
		},
		outOfBounds() {
			this.notifyOutOfBounds();
		},
	},
	mounted() {},
	methods: {
		input(event: TODO) {
			this.$emit("update:modelValue", event.target.value);
			const newValue =
				this.attrs.type === "number"
					? this.formatValue(event.target.valueAsNumber)
					: event.target.value;

			if (newValue === this.modelValue) {
				this.$forceUpdate();
			}

			this.$emit("update:modelValue", newValue);
		},

		/**
		 * Inside this component we already show a warning with message when the value is out of bounds.
		 * But we also want to notify the parent component of this, so they can display a global out of
		 * bounds warning.
		 */
		notifyOutOfBounds() {
			this.$emit("update:outOfBounds", this.outOfBounds || this.valueTooHigh || this.valueTooLow);
		},

		formatValue(value: number) {
			if (this.attrs.step) {
				const step = String(this.attrs.step);
				const decimals = stepAttributeToDecimals(step);

				return toFixedAtMost(Number(value), decimals);
			}
			return value;
		},
	},
};
</script>

<style lang="scss">
.sb_limit-input {
	position: relative;

	&_popover {
		position: absolute;
		width: 2rem;
		height: 1rem;

		// Horizontal sides
		&[data-warning-location^="left"] {
			left: -2rem;
		}

		&[data-warning-location^="right"] {
			right: -2rem;
		}

		&[data-warning-location$="top"] {
			top: 0;
		}

		&[data-warning-location$="bottom"] {
			bottom: 0;
		}

		&[data-warning-location$="v-center"] {
			top: 50%;
			transform: translateY(-50%);
		}

		// Vertical sides
		&[data-warning-location^="top"] {
			top: -1rem;
		}

		&[data-warning-location^="bottom"] {
			bottom: -1rem;
		}

		&[data-warning-location$="left"] {
			left: 0;
		}

		&[data-warning-location$="right"] {
			right: 0;
		}

		&[data-warning-location$="h-center"] {
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&_exclamation {
		position: absolute;
		top: 0;
		color: $brand-red;
		transform: scale(1.2);
	}

	&_info {
		position: absolute;
		top: 0;
		color: $brand-primary-lighter;
		transform: scale(1.2);
	}

	input.s_out-of-bounds {
		background: rgba($brand-error, 0.1);
		border-color: $brand-error;
	}

	input.s_was-limited {
		animation-name: input-limited;
		animation-duration: 1.5s;
		animation-timing-function: ease-out;
		animation-iteration-count: 1;
	}
}

@keyframes input-limited {
	0% {
		background: transparent;
		border-color: rgba($brand-primary-lighter, 0.5);
	}

	15% {
		background: rgba(orange, 0.1);
		border-color: orange;
	}

	100% {
		background: transparent;
		border-color: rgba($brand-primary-lighter, 0.5);
	}
}
</style>
