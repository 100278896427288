import { type Router } from "vue-router";
import { isAuthenticated } from "@/lib/useIsAuthenticated";

export function registerGuard(router: Router) {
	router.beforeEach((to) => {
		if (to.meta.requiresAuth || to.meta.requiresUnauth) {
			const isLoggedIn = isAuthenticated();

			if (to.meta.requiresUnauth && isLoggedIn) {
				return { name: "Configuration" };
			}
			if (to.meta.requiresAuth && !isLoggedIn) {
				return { name: "Authentication" };
			}
		}

		return true;
	});
}
