<template>
	<div class="sb_modal_bg">
		<div class="sb_modal" @click="allowClose && $emit('close')">
			<div class="sb_modal_inner">
				<div class="sb_modal_container" @click.stop>
					<div v-if="allowClose" class="sb_modal_btn-close">
						<button class="sb_button v_naked" @click="allowClose && $emit('close')">
							<ButtonObject icon="icon_ui_close" />
						</button>
					</div>
					<div class="sb_grid">
						<div class="sb_grid_container u_max-width-800">
							<div class="sb_grid_column_1-1">
								<ContentHeader :icon="headerIcon" :title="headerText" />
							</div>
							<div class="sb_grid_column_1-1">
								<slot>Default body!</slot>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from "@/components/assemblies/content-header.vue";

export default {
	name: "PopupModal",
	components: {
		ContentHeader,
	},
	props: {
		allowClose: {
			type: Boolean,
			default: true,
		},
		headerIcon: {
			type: String,
			default: "",
		},
		headerText: {
			type: String,
			default: "",
		},
	},
	emits: { close: null },
};
</script>

<style lang="scss">
.sb_modal {
	position: fixed;
	width: 100%;
	height: 100vh;

	&_inner {
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		z-index: 1002;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		padding: 1rem 1rem 50vh 1rem;
		@include media(">=md") {
			padding: 3rem 1rem 50vh 1rem;
		}
	}

	&_btn-close {
		position: absolute;
		top: 1rem;
		right: 0rem;
		@include media(">=md") {
			top: 2rem;
			right: 1rem;
		}
	}
	&_container {
		position: relative;
		background: #fff;
		box-shadow: 0 0 50px rgba(black, 0.5);
		margin: 0 auto;
		max-width: 800px;
		padding: 2rem 0;
		@include media(">=md") {
			padding: 4rem 2rem;
		}
	}
}

.sb_modal_bg {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 1001;
	background: rgba(#333, 0.7);
}

.sb_modal_loader {
	position: absolute;
	z-index: 1002;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.7);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	padding: 5rem;
}

//
// BRAND STYLING
//

@include sb_content-modal;
</style>
