import axios from "axios";

export default {
	namespaced: true,
	state: {},
	getters: {},
	actions: {
		async keepUserUpdated(store: TODO, payload: TODO) {
			const { data } = await axios.post("/Notification/keep-me-updated", payload);
			return data;
		},

		async informAColleague(store: TODO, payload: TODO) {
			const { data } = await axios.post("/Notification/inform-colleague", payload);
			return data;
		},
	},
};
