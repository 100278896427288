/* eslint-disable vue/multi-word-component-names */
import type { App } from "vue";
import ButtonObject from "@/components/global/button-object.vue";
import CheckBox from "@/components/global/checkbox.vue";
import InputElevator from "@/components/global/input-elevator.vue";
import InputRadio from "@/components/global/input-radio.vue";
import LimitInput from "@/components/global/limit-input.vue";
import SbText from "@/components/global/sb-text.vue";
import SvgIcon from "@/components/global/svg-icon.vue";

export function registerComponents(app: App<Element>) {
	app.component("ButtonObject", ButtonObject);
	app.component("LimitInput", LimitInput);
	app.component("InputElevator", InputElevator);
	app.component("InputRadio", InputRadio);
	app.component("SvgIcon", SvgIcon);
	app.component("SbText", SbText);
	app.component("Checkbox", CheckBox);
}
