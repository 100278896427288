<template>
	<!-- eslint-disable max-len -->
	<div>
		<svg style="position: absolute; top: -9999px">
			<!-- UI ICONS -->
			<symbol id="icon_ui_info" viewBox="0 0 500 500" width="100%" height="100%">
				<path
					d="M467,250c0,119.85-97.15,217-217,217S33,369.85,33,250,130.15,33,250,33A217,217,0,0,1,467,250Z M250,182.35c16.62,0,30.38-13.37,30.38-30s-13.76-30-30.38-30a30.53,30.53,0,0,0-21.42,8.76,29.63,29.63,0,0,0-9,21.21C219.62,169,233.38,182.35,250,182.35Z M250,224.25c-16.62,0-30.38,13.44-30.38,28.82V361.65c0,15.39,13.76,27.94,30.38,27.94s30.38-12.56,30.38-27.94V253.07C280.38,237.67,266.62,224.25,250,224.25Z"
				/>
			</symbol>
			<symbol id="icon_ui_arrow-download" viewBox="0 0 500 500">
				<path
					d="M250,353.24,126.39,229.62l25.84-25.82,79.31,79.32v-212h36.91v212l79.32-79.32,25.84,25.82ZM148.53,428.87H351.46V392H148.53Z"
				/>
			</symbol>
			<symbol id="icon_ui_caret-down" viewBox="0 0 500 500">
				<path
					d="M233.39,315.26,117.47,203.34l33.22-34.6L250,264.68l99.31-95.94,33.22,34.6L266.61,315.26l-16.61,16Z"
				/>
			</symbol>
			<symbol id="icon_ui_caret-left" viewBox="0 0 500 500">
				<path
					d="M184.74,233.39,296.66,117.47l34.6,33.22L235.32,250l95.94,99.31-34.6,33.22L184.74,266.61l-16-16.61Z"
				/>
			</symbol>
			<symbol id="icon_ui_caret-right" viewBox="0 0 500 500">
				<path
					d="M315.27,266.61,203.34,382.53l-34.6-33.22L264.68,250l-95.94-99.31,34.6-33.22L315.27,233.39l16,16.61Z"
				/>
			</symbol>
			<symbol id="icon_ui_caret-up" viewBox="0 0 500 500">
				<path
					d="M266.61,184.74,382.53,296.66l-33.22,34.6L250,235.32l-99.31,95.94-33.22-34.6L233.39,184.74l16.61-16Z"
				/>
			</symbol>
			<symbol id="icon_ui_check" viewBox="0 0 500 500">
				<path d="M114.25,260.19l29.26-37.59,78.18,64.48L350.17,146.2l35.58,31.89L226.68,353.8Z" />
			</symbol>
			<symbol id="icon_ui_close" viewBox="0 0 500 500">
				<path
					d="M368.47,337.78,280.68,250l87.78-87.78a21.7,21.7,0,1,0-30.69-30.69L250,219.31l-87.78-87.78a21.7,21.7,0,0,0-30.69,30.69L219.31,250l-87.78,87.78a21.7,21.7,0,1,0,30.69,30.68L250,280.68l87.78,87.78a21.7,21.7,0,1,0,30.69-30.68Z"
				/>
			</symbol>
			<symbol id="icon_ui_compare" viewBox="0 0 500 500">
				<path
					d="M320.6,354.4h69.59V145.6H285.8V110.81h116A23.19,23.19,0,0,1,425,134V366a23.19,23.19,0,0,1-23.2,23.2h-116V354.4Zm-116-243.59V76H251V424H204.6v-34.8H100.21A23.19,23.19,0,0,1,77,366V134a23.19,23.19,0,0,1,23.2-23.2Z"
				/>
			</symbol>
			<symbol id="icon_ui_exclamation" viewBox="0 0 500 500">
				<path
					d="M250,76.78A173.22,173.22,0,1,0,423.22,250,173.22,173.22,0,0,0,250,76.78ZM275.21,345.1a11,11,0,0,1-11,11H235.77a11,11,0,0,1-11-11V316.65a11,11,0,0,1,11-11h28.46a11,11,0,0,1,11,11Zm0-70.43a11,11,0,0,1-11,11H235.77a11,11,0,0,1-11-11V154.9a11,11,0,0,1,11-11h28.46a11,11,0,0,1,11,11Z"
				/>
			</symbol>
			<symbol id="icon_ui_log-out" viewBox="0 0 500 500">
				<path
					d="M170.44,266l57.38,57.09-22.49,22.77L109.51,250l95.82-95.82,22.49,22.77L170.44,234H324.53V266ZM297.32,122.23v31.94h61.23V345.82H297.32v31.95h93.17V122.23Z"
				/>
			</symbol>
			<symbol id="icon_ui_plus" viewBox="0 0 500 500">
				<path
					d="M249.38,112.75c13.55,0,24.62,11.08,24.62,23.39V226h88.63c13.54,0,24.62,11.07,24.62,23.38A24.69,24.69,0,0,1,362.63,274H274v88.63a24.69,24.69,0,0,1-24.62,24.62c-12.31,0-23.38-11.08-23.38-24.62V274H136.14c-12.31,0-23.39-11.07-23.39-24.62,0-12.31,11.08-23.38,23.39-23.38H226V136.14C226,123.83,237.07,112.75,249.38,112.75Z"
				/>
			</symbol>
			<symbol id="icon_ui_print" viewBox="0 0 500 500">
				<path
					d="M424.84,366.42a9.16,9.16,0,0,0-6.53-2.61h-36.4V263.66a9.1,9.1,0,0,0-9.11-9.1H127.07a9.09,9.09,0,0,0-9.1,9.1V363.81H90.66V199.93H409.21V336.5a9.1,9.1,0,1,0,18.2,0V190.83a9.1,9.1,0,0,0-9.1-9.11h-36.4v-41a9.11,9.11,0,0,0-9.11-9.11H318.2V81.58a9.1,9.1,0,0,0-9.1-9.11h-182c-.28,0-.57,0-.85,0A9.12,9.12,0,0,0,118,81.58V181.72H80.71a9.12,9.12,0,0,0-8.25,9.11V372.91a9.1,9.1,0,0,0,9.1,9.11H118v36.42a9.09,9.09,0,0,0,9.1,9.1H372.8a9.1,9.1,0,0,0,9.11-9.1V382h36.4a9.12,9.12,0,0,0,6.53-15.6ZM136.17,90.68H300v50.08a9.1,9.1,0,0,0,9.11,9.1h54.6v31.86H136.17Zm202.2,284.17a9.13,9.13,0,0,1-6.52,2.62H168a9.11,9.11,0,0,1-.85-18.21,6,6,0,0,1,.85,0H331.85a9.08,9.08,0,0,1,6.52,2.61,9.13,9.13,0,0,1,0,13Zm0-54.62a9.08,9.08,0,0,1-6.52,2.61H168a9.11,9.11,0,0,1-.85-18.21,6,6,0,0,1,.85,0H331.85a9.12,9.12,0,0,1,6.52,15.6Z"
				/>
			</symbol>
			<symbol id="icon_ui_restore" viewBox="0 0 500 500">
				<path
					d="M71.58,404.11a6.14,6.14,0,0,1-10.49-4.33V320.24a6.14,6.14,0,0,1,6.14-6.14h79.24a6.14,6.14,0,0,1,4.35,10.47l-27.93,28,3.21,3.48c30.52,33.18,73.61,52.47,118,52.47a158.56,158.56,0,0,0,0-317.12,156.2,156.2,0,0,0-108.19,43.62l-2.46,2.37-25.74-26.77,2.47-2.36c36.42-34.78,83.81-54,133.92-54,107.7,0,194.8,87.48,194.8,195.72S351.8,445.72,244.11,445.72A193.3,193.3,0,0,1,101,382.47l-3.74-4.12Z"
				/>
			</symbol>

			<!-- OTHER ICONS -->
			<symbol id="icon_cured-meat" viewBox="0 0 500 500">
				<path
					d="M288.54,231.56h0A13.06,13.06,0,0,0,299.77,238h114A13,13,0,0,0,425,231.56l57-98a12.78,12.78,0,0,0,0-12.9l-57-98a13.07,13.07,0,0,0-11.25-6.44h-114a13,13,0,0,0-11.23,6.45l-57,98a12.91,12.91,0,0,0,0,12.88ZM307.27,42.05h99l49.5,85.08-49.5,85.1h-99l-49.48-85.1ZM425,268.47h0A13.07,13.07,0,0,0,413.77,262h-114a13,13,0,0,0-11.24,6.45l-57,98a13,13,0,0,0,0,12.88l57,98a13,13,0,0,0,11.23,6.44h114A13,13,0,0,0,425,477.28l57-97.95a12.8,12.8,0,0,0,0-12.9ZM406.25,458h-99l-49.48-85.08,49.48-85.07h99l49.5,85.07ZM268.39,242.73l-57-97.94a13,13,0,0,0-11.25-6.46H86.21A13,13,0,0,0,75,144.77l-57,98a12.91,12.91,0,0,0,0,12.87l57,98a13.08,13.08,0,0,0,11.24,6.44h114a13,13,0,0,0,11.24-6.45l57-97.94A12.71,12.71,0,0,0,268.39,242.73Zm-75.73,91.55H93.71L44.2,249.18,93.69,164.1h98.95l49.51,85.08Z"
				/>
			</symbol>
			<symbol id="icon_question-mark-in-circle" viewBox="0 0 500 500">
				<path
					d="M266.54,140.05a77.8,77.8,0,0,0-94.26,76,17.18,17.18,0,1,0,34.35,0,43.39,43.39,0,1,1,58,40.85,47.85,47.85,0,0,0-31.8,44.93v5a17.19,17.19,0,0,0,34.38,0v-5.24a13.13,13.13,0,0,1,9-12.36c36.95-13.21,57.92-51,49.83-89.74-6.12-29.43-30-53.33-59.47-59.47M250,337.13a15.14,15.14,0,1,0,15.16,15.12A15.11,15.11,0,0,0,250,337.13M250,9.46C117.16,9.46,9.46,117.15,9.46,250S117.15,490.54,250,490.54,490.54,382.85,490.54,250,382.85,9.46,250,9.46m0,446.72C136.31,456.18,43.82,363.69,43.82,250S136.31,43.82,250,43.82,456.18,136.31,456.18,250,363.69,456.18,250,456.18"
				/>
			</symbol>
			<symbol id="icon_refrigerated-food" viewBox="0 0 500 500">
				<path
					d="M373.67,301.23H319.94l-26.15-26.16,11.83-11.84h30.05ZM263.23,333.5V305.63l11.84-11.84,26.15,26.15v51.55ZM250,278l-28-28,28-28,28,28ZM236.77,333.5l-38,38V319.94l26.15-26.15,11.84,11.83Zm-56.71-32.27H128.51l38-38h27.88l11.83,11.84Zm1.54-100.92,24.61,24.62-11.83,11.84H166.5l-36.45-36.46Zm18.71-72.44,36.46,36.45v30.06l-11.84,11.84L200.31,181.6Zm62.92,36.45,36.46-36.45V181.6l-24.62,24.62-11.84-11.85Zm55.17,36h53.73l-36.46,36.46h-30l-11.84-11.84Zm152.25,36.46H443.42l20.47-20.47-18.71-18.71L406,236.77H373.1l38-38-12.48-12.49V173.85h-53.8l23.26-23.26h55.42V124.12H394.59l20.79-20.79a13.23,13.23,0,0,0-18.71-18.71l-20.8,20.79V76.47H349.41v55.41l-23.26,23.26v-53.8h-12.4L301.27,88.86l-38,38V94l39.18-39.18L283.7,36.11,263.23,56.57V29.35a13.23,13.23,0,0,0-26.46,0V56.57L216.3,36.11,197.59,54.82,236.77,94v32.9l-38-38-12.48,12.48h-12.4v53.8l-23.26-23.26V76.47H124.13v28.94l-20.8-20.79a13.23,13.23,0,1,0-18.71,18.71l20.79,20.79H76.47v26.47h55.42l23.26,23.26h-53.8v14.57L91,198.73l38,38H96.18L57,197.59,38.29,216.3l20.46,20.47H29.35a13.23,13.23,0,1,0,0,26.46h29.4L38.29,283.7,57,302.41l39.18-39.18h32.89l-38,38L99.8,310v17.65h53.8L130.34,351H74.93v26.47h28.94L84.62,396.67a13.23,13.23,0,0,0,18.71,18.71l19.25-19.25v28.94h26.47V369.66l23.26-23.26v53.8H190l8.77,8.77,38-38v32.89L197.59,443l18.71,18.71,20.47-20.46v29.4a13.23,13.23,0,0,0,26.46,0v-29.4l20.47,20.46L302.41,443l-39.18-39.18V370.93l38,38L310,400.2h17.65V346.4L351,369.66v55.41h26.47V396.13l19.25,19.25a13.23,13.23,0,0,0,18.71-18.71l-19.25-19.25h28.94V351H369.66L346.4,327.69h53.8V312.22l10.94-11-38-38H406l39.18,39.18,18.71-18.71-20.47-20.47h27.23a13.23,13.23,0,1,0,0-26.46Z"
				/>
			</symbol>
			<symbol id="icon_uncured-meat-and-seafood" viewBox="0 0 500 500">
				<path
					d="M445.4,378a36.64,36.64,0,0,1-65.53-28.52l-93.71-60.06a14.59,14.59,0,0,0-22.47,12.29V413a36.64,36.64,0,1,1-27.38,0V301.73a14.59,14.59,0,0,0-22.47-12.29L120.13,349.5a36.63,36.63,0,1,1-14.77-23.06l100.09-64.15a14.6,14.6,0,0,0,0-24.58L105.36,173.55a36.65,36.65,0,1,1,14.77-23.05l93.71,60.06a14.59,14.59,0,0,0,22.47-12.29V87a36.64,36.64,0,1,1,27.38,0V198.27a14.59,14.59,0,0,0,22.47,12.29l93.71-60.06a36.64,36.64,0,1,1,14.77,23.05L294.55,237.71a14.6,14.6,0,0,0,0,24.58l100.09,64.15A36.65,36.65,0,0,1,445.4,378"
				/>
			</symbol>
			<symbol id="icon_ui_handle" viewBox="0 0 500 500">
				<path
					d="M417.1,378.6H82.9c-7.1,0-12.9,5.8-12.9,12.9v25.7c0,7.1,5.8,12.9,12.9,12.9h334.3c7.1,0,12.9-5.8,12.9-12.9v-25.7 C430,384.3,424.2,378.6,417.1,378.6z M417.1,275.7H82.9c-7.1,0-12.9,5.8-12.9,12.9v25.7c0,7.1,5.8,12.9,12.9,12.9h334.3 c7.1,0,12.9-5.8,12.9-12.9v-25.7C430,281.5,424.2,275.7,417.1,275.7z M417.1,172.9H82.9c-7.1,0-12.9,5.8-12.9,12.9v25.7 c0,7.1,5.8,12.9,12.9,12.9h334.3c7.1,0,12.9-5.8,12.9-12.9v-25.7C430,178.6,424.2,172.9,417.1,172.9z M417.1,70H82.9 C75.8,70,70,75.8,70,82.9v25.7c0,7.1,5.8,12.9,12.9,12.9h334.3c7.1,0,12.9-5.8,12.9-12.9V82.9C430,75.8,424.2,70,417.1,70z"
				/>
			</symbol>
			<symbol id="icon_ui_elevator_sort" viewBox="0 0 500 500">
				<path
					d="M133.502 281.635H365.924C386.822 281.635 397.271 306.928 382.525 321.674L266.314 437.885C257.134 447.064 242.291 447.064 233.209 437.885L116.9 321.674C102.154 306.928 112.603 281.635 133.502 281.635ZM382.525 179.096L266.314 62.8848C257.134 53.7051 242.291 53.7051 233.209 62.8848L116.9 179.096C102.154 193.842 112.603 219.135 133.502 219.135H365.924C386.822 219.135 397.271 193.842 382.525 179.096Z"
				/>
			</symbol>
			<symbol id="icon_ui_file" viewBox="0 0 500 500">
				<svg
					id="Layer_1"
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					width="500"
					height="500"
					viewBox="0 0 500 500"
				>
					<path
						d="M352,420a34,34,0,0,0,34-34V182L284,80H148a34,34,0,0,0-34,34V386a34,34,0,0,0,34,34ZM267,114l85,85H267ZM165,182h51v34H165Zm0,68H335v34H165Zm0,68H335v34H165Z"
					/>
				</svg>
			</symbol>
			<symbol id="icon_ui_pdf" viewBox="0 0 500 500">
				<svg
					id="a"
					xmlns="http://www.w3.org/2000/svg"
					width="500"
					height="500"
					viewBox="0 0 500 500"
				>
					<path
						d="m241.24,162.77c-3.25,0-6.5.54-9.57,1.44v-64.3c0-4.88,4.15-9.03,9.03-9.03s9.03,4.15,9.03,9.03v64.3c-3.07-.72-5.96-1.26-8.67-1.26l.18-.18Zm-108.54,51.47h66.28c1.99,0,3.79-.36,5.78-.54-.9-5.96-1.27-12.1-.18-18.24-1.81.36-3.61.54-5.6.54h-66.28c-4.88,0-9.03,4.15-9.03,9.03s4.15,9.03,9.03,9.03v.18Zm234.79,116.31c1.63,3.61,2.89,10.65-4.15,18.6-1.99,2.35-5.78,4.88-11.56,4.88-3.25,0-14.09,0-41-23.12-6.68-.36-12.1-.36-14.09,0-29.8,2.89-62.49,10.29-91.03,20.23-1.63,1.99-3.25,4.15-4.88,6.14l-1.99,2.53c-16.25,19.51-33.59,37.75-49.31,37.75-4.88,0-9.03-1.63-12.82-4.88-4.15-4.15-6.32-9.03-5.42-14.45,1.99-12.82,18.96-22.76,37.2-31.43,7.77-4.15,16.62-7.95,25.65-11.2,18.24-26.91,32.69-69.89,40.09-95.54-12.82-23.3-15.71-39.73-8.67-50.57,3.79-5.42,9.57-8.67,15.71-8.67,3.25,0,19.87,1.63,19.87,29.8,0,.72-.36,1.08-.36,1.99,0,.36-2.53,10.29-6.68,25.29,14.99,24.02,39.73,53.46,63.75,74.95v-.18c43.53,2.53,48.04,13.73,49.67,17.88Zm-202.46,38.65c-8.67,4.51-12.82,7.95-14.45,9.93,2.89-.36,7.04-2.89,14.45-9.93Zm76.04-156.4c.54-1.63.9-2.35.9-3.25,0-5.06-.72-8.31-1.63-9.57h-.18c-.72,1.99-.72,6.14.9,12.82Zm50.21,100.6c-16.07-16.25-31.79-34.86-44.25-52.19-7.04,21.49-15.71,45.51-26.55,66.28,23.3-7.04,48.04-12.1,70.8-14.09Zm121.37-223.95v321.12c0,21.13-17.88,38.11-39.37,38.11H126.74c-21.49,0-39.37-16.98-39.37-38.11v-218.17c0-2.35.9-4.52,2.53-6.14L221.56,54.22c1.81-1.63,4.15-2.89,6.68-2.89h145.03c22.03,0,39.37,16.98,39.37,38.11Zm-18.24,0c0-10.84-9.57-19.51-21.13-19.51h-141.23l-126.42,126.42v214.74c0,10.84,9.57,19.51,21.13,19.51h246.53c11.56,0,21.13-8.67,21.13-19.51V89.44Z"
					/>
				</svg>
			</symbol>
		</svg>
	</div>
	<!-- eslint-enable max-len -->
</template>

<script>
export default {
	name: "SvgSymbols",
};
</script>

<style lang="scss" scoped></style>
