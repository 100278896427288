<script setup lang="ts">
import { type HTMLAttributes } from "vue";
import { Primitive, type PrimitiveProps } from "radix-vue";
import { cx } from "@/lib/style/cva.config";
import { type ButtonVariants, buttonVariants } from ".";

interface Props extends PrimitiveProps {
	variant?: ButtonVariants["variant"];
	size?: ButtonVariants["size"];
	class?: HTMLAttributes["class"];
}

const props = withDefaults(defineProps<Props>(), {
	as: "button",
	variant: "default",
	size: "default",
	class: undefined,
});
</script>

<template>
	<Primitive
		:as="as"
		:as-child="asChild"
		data-unstyled
		:class="cx(buttonVariants({ variant, size }), props.class)"
	>
		<slot />
	</Primitive>
</template>
