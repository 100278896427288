import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { z } from "zod";

export const MiscSchema = z.object({
	resultsClosed: z.coerce.boolean().default(true),
});

export const useMiscStore = defineStore("misc", () => {
	const resultsClosed = ref(MiscSchema.shape.resultsClosed.parse(undefined));

	const resultsShown = computed<boolean>(() => {
		return !resultsClosed.value;
	});

	return { resultsClosed, resultsShown };
});
