import { createStore } from "vuex";
import calculator from "./modules/calculator";
import global from "./modules/global";
import notification from "./modules/notification";
import permission from "./modules/permission";
import user from "./modules/user";

export const store = createStore({
	state: {},
	getters: {},
	modules: {
		global,
		calculator,
		user,
		permission,
		notification,
	},
	plugins: [
		(store) => {
			store.commit("global/loadFromLocalStorage");
			store.commit("user/loadFromLocalStorage");
		},
	],
});
