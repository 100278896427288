<template>
	<h1 v-if="type === 'h1'" class="sb_text sb_text--h1" v-bind="$attrs">
		<slot></slot>
	</h1>

	<h2 v-else-if="type === 'h2'" class="sb_text sb_text--h2" v-bind="$attrs">
		<slot></slot>
	</h2>

	<h3 v-else-if="type === 'h3'" class="sb_text sb_text--h3" v-bind="$attrs">
		<slot></slot>
	</h3>

	<h4 v-else-if="type === 'h4'" class="sb_text sb_text--h4" v-bind="$attrs">
		<slot></slot>
	</h4>

	<p v-else-if="type === 'p'" class="sb_text sb_text--p" v-bind="$attrs">
		<slot></slot>
	</p>

	<b v-else-if="type === 'b'" class="sb_text sb_text--b" v-bind="$attrs">
		<slot></slot>
	</b>

	<label v-else-if="type === 'label'" class="sb_text sb_text--label" v-bind="$attrs">
		<slot></slot>
	</label>

	<small v-else-if="type === 'error'" class="sb_text sb_text--error" v-bind="$attrs">
		<slot></slot>
	</small>

	<p v-else class="sb_text" v-bind="$attrs">
		<slot></slot>
	</p>
</template>

<script>
export default {
	name: "SbText",
	props: {
		type: {
			type: String, // 'body' | 'error' | 'label' | 'p' | 'h4' | 'h3' | 'h2' | 'h1'
			default: "body",
		},
	},
};
</script>

<style lang="scss">
.sb_text {
	--color: #{$brand-gray};
	--font-weight: 500;
	--font-size: 16px;
	--line-height: 22px;
	--text-transform: none;
	--letter-spacing: auto;
	--transition-property: all;
	--transition-duration: #{$transition-duration};
	--transition-timing-function: #{$transition-timing-function};

	font-family: $body-font-family;
	font-weight: var(--font-weight);
	font-size: var(--font-size);
	line-height: var(--line-height);
	letter-spacing: var(--letter-spacing);
	color: var(--color);
	text-transform: var(--text-transform);
	overflow-wrap: break-word;
	transition-property: var(--transition-property);
	transition-duration: var(--transition-duration);
	transition-timing-function: var(--transition-timing-function);

	&--h1 {
		--color: #{$brand-primary-darker};
		--font-weight: 700;
		--font-size: 32px;
		--line-height: 44px;
	}

	&--h2 {
		--color: #{$brand-primary-darker};
		--font-weight: 700;
		--font-size: 28px;
		--line-height: 39px;
	}

	&--h3 {
		--color: #{$brand-primary};
		--font-weight: 700;
		--font-size: 24px;
		--line-height: 29px;

		@media print {
			--font-size: 20px;
		}
	}

	&--h4 {
		--color: #{$brand-primary};
		--font-weight: 700;
		--font-size: 18px;
		--line-height: 24px;
	}

	&--p {
		--color: #{$brand-primary};
		--font-weight: 400;
		--font-size: 15px;
		--line-height: 25px;

		@media print {
			--line-height: 18px;
		}
	}

	&--b {
		--color: #{$brand-primary};
		--font-weight: 600;
		--font-size: 15px;
		--line-height: 25px;
	}

	&--label {
		--color: #{$brand-primary};
		--font-weight: 700;
		--line-height: 24px;
		--letter-spacing: 0.1em;
		--text-transform: uppercase;

		@media print {
			--font-size: 8px;
		}
	}

	&--error {
		--color: #{$brand-error};
		--font-size: 14px;
	}
}
</style>
