<template>
	<div class="sb_loader">
		<div class="sb_loader_inner"></div>
	</div>
</template>

<script>
export default {
	name: "Loader",
	data() {
		return {};
	},
};
</script>

<style lang="scss">
.sb_loader {
	display: inline-block;
	&_inner {
		display: inline-block;
		border-radius: 100%;
		margin: 3px;
		border: 3px solid #000;
		border-bottom-color: transparent;
		height: 1.4rem;
		width: 1.4rem;
		background: 0 0 !important;
		animation: rotate 0.75s 0s linear infinite;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

//
// BRAND STYLING
//
@include sb_loader;
</style>
