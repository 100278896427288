<script setup lang="ts">
import { type HTMLAttributes } from "vue";
import { Primitive, type PrimitiveProps } from "radix-vue";
import { cva, cx } from "@/lib/style/cva.config";

const textVariants = cva({
	base: "font-sans text-brand-primary",
	variants: {
		variant: {
			default: "",
			heading: "text-brand-primary-darker font-bold text-3xl",
			muted: "text-brand-gray",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

interface Props extends PrimitiveProps {
	variant?: NonNullable<Parameters<typeof textVariants>[0]>["variant"];
	class?: HTMLAttributes["class"];
}

const props = withDefaults(defineProps<Props>(), {
	as: "span",
	variant: "default",
	class: undefined,
});
</script>

<template>
	<Primitive :as="as" :as-child="asChild" :class="cx(textVariants({ variant }), props.class)">
		<slot />
	</Primitive>
</template>
