<template>
	<div class="sb_button_container">
		<div v-if="hasDefaultSlot" class="sb_button_text">
			<slot></slot>
		</div>
		<Loader v-if="loading" style="transform: scale(0.5) translateY(-5px); height: 10px" />
		<div v-if="icon && !loading" class="sb_button_icon">
			<SvgIcon :icon-id="icon" />
		</div>
	</div>
</template>

<script lang="ts">
import Loader from "@/components/objects/loader.vue";

export default {
	name: "ButtonObject",
	components: {
		Loader,
	},
	props: {
		icon: {
			type: String,
			default: "",
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		hasDefaultSlot() {
			return !!this.$slots.default;
		},
	},
};
</script>

<style lang="scss">
.sb_button {
	padding: 0.8rem 1rem;
	text-align: left;
	border-radius: 0.15rem;
	border: none;
	position: relative;
	transition: opacity 0.3s ease;
	font-size: 1.1rem;
	letter-spacing: 0.5px;
	background: none;
	color: $default-text-color;
	user-select: none;
	text-decoration: none;
	transition:
		color 0.3s ease,
		background-color 0.3s ease;
	margin: 0 0.6rem 0.6rem 0;
	display: inline-flex;

	@include media(">=md") {
		font-size: 0.95rem;
		padding: 0.6rem 0.8rem 0.7rem 0.6rem;
	}

	&_container {
		display: inline-flex;
		align-items: center;
	}

	&_text {
		line-height: 1;
	}

	&_icon {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		transform: scale(1.4);
	}

	&:focus,
	&:hover {
		color: currentColor;
		cursor: pointer;
		outline: none;
	}

	//
	// DISABLED
	//
	&:disabled {
		opacity: 0.4;
		pointer-events: none;
	}

	//
	// ICONS
	//
	&.v_has-icon-right {
		.sb_button_icon {
			margin-left: 0.6rem;
		}
	}
	&.v_has-icon-left {
		.sb_button_icon {
			margin-left: 0;
			margin-right: 0.6rem;
		}
		.sb_button_container {
			flex-direction: row-reverse !important;
		}
	}
	&.v_icon-faded {
		.sb_button_icon {
			opacity: 0.5;
		}
	}

	// rotate icon
	.sb_icon {
		transition: transform 0.2s ease;
	}
	&.s_rotate-icon-180 {
		.sb_icon {
			transform: rotate(180deg);
		}
	}

	//
	// EXPAND
	//

	&.v_expanded {
		display: block;
		width: 100%;
		.sb_button_container {
			display: flex;
			justify-content: center;
		}
	}
	&.v_expand-on-small {
		@include media("<md-portrait") {
			display: block;
			width: 100%;
			text-align: center;
			.sb_button_container {
				display: flex;
				justify-content: center;
			}
		}
	}
}
</style>
