<template>
	<div class="sb_header">
		<div class="sb_header_top">
			<div class="sb_header_top_left">
				<div v-if="icon" class="sb_header_icon">
					<SvgIcon :icon-id="icon" />
				</div>
				<div v-if="step" class="sb_header_step">
					{{ step }}
				</div>
				<div class="sb_header_heading">
					<h2>
						<!-- eslint-disable-next-line vue/no-v-html -->
						<span v-html="title"></span>
						<ButtonInfo v-if="info" @click="$emit('info-click', info)" />
					</h2>
				</div>
			</div>
			<div class="sb_header_top_right">
				<slot name="right"></slot>
			</div>
		</div>
		<div class="sb_header_description">
			<slot name="description"></slot>
		</div>
	</div>
</template>

<script lang="ts">
import ButtonInfo from "@/components/objects/button-info.vue";

export default {
	name: "ContentHeader",
	components: {
		ButtonInfo,
	},
	props: {
		title: {
			type: String,
			default: "",
		},
		step: {
			type: String,
			default: "",
		},
		icon: {
			type: String,
			default: "",
		},
		info: {
			type: String,
			default: "",
		},
	},
	emits: { "info-click": null },
};
</script>

<style lang="scss">
.sb_header {
	@include media(">=md-portrait") {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&_top {
		display: flex;
		align-items: center;
		width: 100%;
	}
	&_top_left {
		display: flex;
		align-items: center;
		flex: 1 1 auto;
	}
	&_icon {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-right: 0.6rem;
		font-size: 2rem;
	}
	&_step {
		position: relative;
		width: 2rem;
		margin-right: 1rem;
		text-align: center;
		line-height: 2rem;
		font-weight: bold;
		&:before {
			content: "";
			position: absolute;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}
	&_heading {
	}

	&_top_right {
		display: flex;
		justify-content: flex-end;
	}

	&_description {
		width: 100%;
		max-width: 512px;
		margin: 1rem 0 0 0;
		font-size: 1.1rem;
	}

	&.v_description-min-height {
		.sb_header_description {
			min-height: 80px;
		}
	}
}

//
// BRAND STYLING
//

@include sb_content-header;
</style>
