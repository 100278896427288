<template>
	<div class="sb_icon">
		<svg role="img">
			<use :xlink:href="'#' + iconId" />
		</svg>
	</div>
</template>

<script>
export default {
	name: "SvgIcon",
	props: {
		iconId: {
			type: String,
			default: "",
		},
	},
};
</script>

<style lang="scss" scoped>
.sb_icon {
	width: 1em;
	height: 1em;
	fill: currentColor;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	svg {
		width: 1em;
		height: 1em;
		fill: currentColor;
	}
}
</style>
