<template>
	<footer class="mx-auto flex w-full max-w-screen-md flex-col gap-4 p-4" v-bind="$attrs">
		<Separator />

		<div class="flex flex-wrap items-center justify-center gap-4">
			<Button
				:as="RouterLink"
				:to="{ name: 'Disclaimer' }"
				variant="link"
				size="auto"
				class="text-brand-gray"
				>Disclaimer</Button
			>
			<Button
				as="a"
				href="https://www.corbion.com/en/Request-a-sample"
				variant="link"
				size="auto"
				class="text-brand-gray"
				>Sample request</Button
			>
			<Button
				variant="link"
				size="auto"
				class="text-brand-gray"
				@click="showKeepMeUpdatedModal = true"
			>
				Keep me updated
			</Button>
			<Button
				variant="link"
				size="auto"
				class="text-brand-gray"
				@click="showInformColleagueModal = true"
			>
				Share this tool
			</Button>

			<Text variant="muted" class="text-sm text-brand-gray">v{{ version }}</Text>
		</div>

		<transition name="fade">
			<PopupModalKeepMeUpdated
				v-show="showKeepMeUpdatedModal"
				@close="showKeepMeUpdatedModal = false"
			/>
		</transition>
		<transition name="fade">
			<PopupModalInformColleague
				v-show="showInformColleagueModal"
				@close="showInformColleagueModal = false"
			/>
		</transition>
	</footer>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { RouterLink } from "vue-router";
import { version } from "@/../package.json";
import PopupModalInformColleague from "@/components/assemblies/popup-modal-inform-colleague.vue";
import PopupModalKeepMeUpdated from "@/components/assemblies/popup-modal-keep-me-updated.vue";
import { Button } from "@/components/ui/button";
import Text from "@/components/ui/custom/Text.vue";
import { Separator } from "@/components/ui/separator";

const showKeepMeUpdatedModal = ref<boolean>(false);
const showInformColleagueModal = ref<boolean>(false);
</script>
