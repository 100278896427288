<template>
	<div class="sb_input-elevator">
		<!-- eslint-disable-next-line -->
		<VDropdown boundariesElement="#app" :open="isPopoverShown">
			<input
				:value="modelValue"
				type="text"
				placeholder="Type or select a label..."
				class="sb_temperature-point_input tooltip-target"
				@click="isPopoverShown = true"
				@change="handleChange"
			/>

			<SvgIcon icon-id="icon_ui_elevator_sort" />

			<template #popper>
				<div class="list tooltip-content">
					<button
						v-for="place in list"
						:key="place"
						@click="
							$emit('update:modelValue', place);
							isPopoverShown = false;
						"
					>
						{{ place }}
					</button>
				</div>
			</template>
		</VDropdown>
	</div>
</template>

<script lang="ts">
import type { PropType } from "vue";

export default {
	name: "InputElevator",
	props: {
		modelValue: {
			type: String,
			required: true,
		},
		list: {
			type: Array as PropType<Array<string>>,
			required: false,
			default: () => [
				"Production",
				"Food service",
				"Storage",
				"Transportation",
				"Retail",
				"Consumer",
			],
		},
	},
	emits: { "update:modelValue": String },
	data() {
		return {
			isPopoverShown: false,
		};
	},
	methods: {
		handleChange(event: Event) {
			this.$emit("update:modelValue", (event.target as HTMLInputElement).value);
		},
	},
};
</script>

<style lang="scss" scoped>
.sb_input-elevator {
	position: relative;

	input {
		padding-inline-end: 2em;
	}

	.sb_icon {
		position: absolute;
		right: 0.5em;
		top: 0.25em;
		pointer-events: none;
		color: rgba(68, 149, 209, 0.5);
	}
}

.list {
	margin: -1.5em;
	min-width: 20ch;
	display: flex;
	flex-direction: column;
	z-index: 100;

	> button {
		padding: 0.5em 1em;
		color: #262c68;

		border-bottom: 2px solid rgba(68, 149, 209, 0.5);

		&:first-of-type {
			border-top-left-radius: 0.2rem;
			border-top-right-radius: 0.2rem;
		}

		&:last-of-type {
			border-bottom-left-radius: 0.2rem;
			border-bottom-right-radius: 0.2rem;
			border-bottom: none;
		}

		&:hover,
		&:active {
			background: rgba(68, 149, 209, 0.1);
		}
	}
}
</style>
