import { type ComputedRef, computed } from "vue";
import { store } from "@/store/store";

export function isAuthenticated(): boolean {
	return (store.state as TODO).user.token;
}

export function useIsAuthenticated(): ComputedRef<boolean> {
	return computed(() => isAuthenticated());
}
