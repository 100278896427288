<template>
	<div class="sb_input_radio_svg">
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
			<title>radio_input</title>
			<circle cx="10.5" cy="10.5" r="10.5" class="sb_input_radio_svg_circle-bg" />
			<circle
				cx="10.5"
				cy="10.5"
				r="3.5"
				style="fill: #fff"
				class="sb_input_radio_svg_circle-selected"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: "InputRadio",
};
</script>

<style lang="scss" scoped>
.sb_input_radio_svg {
	position: absolute;
	top: 0;
	left: 0;

	svg {
		width: 21px;
		height: 21px;

		circle {
			transition: fill 0.15s ease;
		}
	}
	&_circle-selected {
		opacity: 0;
		transition: opacity 0.15s ease;
	}
	&_circle-bg {
		fill: #eee;
	}
}

//
// BRAND STYLING
//
@include sb_input-radio;
</style>
