import { type RouteRecordRaw, createRouter, createWebHashHistory } from "vue-router";
import { registerGuard } from "@/router/Guard";
import { store } from "@/store/store";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Authentication",
		component: () => import("@/views/Home.vue"),
		meta: {
			requiresUnauth: true,
		},
	},
	{
		path: "/configuration",
		name: "Configuration",
		component: () => import("@/views/SelectRegionAndFoodType.vue"),
		meta: {
			requiresAuth: true,
			showFixedActionBar: true,
		},
	},
	{
		path: "/calculate",
		name: "Calculate",
		component: () => import("@/views/Calculate.vue"),
		meta: {
			requiresAuth: true,
			showFixedActionBar: true,
		},
		beforeEnter: () => {
			if (
				store.getters["global/getRegion"] === null ||
				store.getters["global/getFoodType"] === null
			) {
				return { name: "Configuration" };
			}

			return true;
		},
	},
	{
		path: "/result/:id",
		name: "Result",
		component: () => import("@/views/Result.vue"),
		meta: {
			requiresAuth: true,
			hideHeader: true,
			hideFooter: true,
		},
	},
	{
		path: "/faq",
		name: "FAQ",
		component: () => import("@/views/FAQ.vue"),
	},
	{
		path: "/disclaimer",
		name: "Disclaimer",
		component: () => import("@/views/Disclaimer.vue"),
	},
	{ path: "/:pathMatch(.*)*", redirect: "/" },
];

export const router = createRouter({
	history: createWebHashHistory(import.meta.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (["Disclaimer"].includes(String(to.name)) || !(to.name === "FAQ" && from.name === "FAQ")) {
			return { top: 0 };
		}

		if (savedPosition) {
			return savedPosition;
		}
	},
});

registerGuard(router);
