<template>
	<div>
		<slot></slot>
	</div>
</template>

<script setup lang="ts">
import { onErrorCaptured, toRefs } from "vue";

const props = withDefaults(defineProps<{ stopPropagation?: boolean }>(), {
	stopPropagation: false,
});
const { stopPropagation } = toRefs(props);

const emit = defineEmits<{ "error-captured": Parameters<Parameters<typeof onErrorCaptured>[0]> }>();

onErrorCaptured((error, instance, info) => {
	console.error("Error captured", error);

	emit("error-captured", error, instance, info);

	if (stopPropagation.value) return false;
	return;
});
</script>
