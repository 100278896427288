/* eslint-disable no-restricted-imports */
import { defineConfig } from "cva";
import { twMerge } from "./twMerge.config";

export const { cva, cx, compose } = defineConfig({
	hooks: {
		onComplete: (className) => twMerge(className),
	},
});

export type * from "cva";
