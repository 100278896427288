<template>
	<VDropdown :shown="showError" :theme="getTheme">
		<div class="sb_checkbox" :data-checked="checked">
			<input
				:id="id"
				type="checkbox"
				class="sb_checkbox_element"
				:checked="checked"
				v-bind="$attrs"
				:data-error="showError"
				:data-error-message="errorMessage"
				@input="handle"
			/>

			<sb-icon name="done" class="sb_checkbox_check" />
		</div>

		<template #popper>
			<sb-text v-if="errorMessage" type="p">{{ errorMessage }}</sb-text>
		</template>
	</VDropdown>
</template>

<script lang="ts">
export default {
	props: {
		id: {
			type: String,
			default: `sb_checkbox_${crypto.randomUUID()}`,
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: undefined,
		},
		inModal: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: Boolean,
			required: true,
		},
		tooltipLocation: {
			type: String,
			default: "inline-start", //'inline-start' | 'inline-end';
		},
	},
	emits: { "update:modelValue": null },
	data() {
		return {
			checked: false,
			supressError: false,
		};
	},
	computed: {
		showError() {
			return (this.error || !!this.errorMessage) && !!this.errorMessage && !this.supressError;
		},
		getTheme() {
			let theme = "input";

			if (this.inModal) {
				theme += "-modal";
			}

			if (this.showError) {
				theme += "-error";
			}

			theme += `-${this.tooltipLocation}`;

			return theme;
		},
	},
	watch: {
		modelValue(newValue) {
			this.checked = newValue;
		},
		errorMessage() {
			this.supressError = false;
		},
	},
	methods: {
		handle() {
			this.checked = !this.checked;
			this.supressError = true;
			this.$emit("update:modelValue", this.checked);
		},
	},
};
</script>

<style lang="scss">
.sb_checkbox {
	--checkbox-size: 24px;
	--transition-duration: #{$transition-duration-short};
	--transition-timing-function: #{$transition-timing-function};
	--active-color: #{$brand-primary-lighter};
	--background: #{$brand-white};

	position: relative;

	&_element {
		display: block;
		position: relative;
		appearance: none;
		cursor: pointer;
		width: var(--checkbox-size);
		height: var(--checkbox-size);
		background: var(--background);
		border-width: $border-width * 2;
		border-style: $border-style;
		border-color: var(--active-color);
		border-radius: $border-radius;
		transition-property: background, border-width, border-color;
		transition-duration: var(--transition-duration);
		transition-timing-function: var(--transition-timing-function);

		&:disabled,
		&[disabled="true"] {
			filter: grayscale(100%);
			cursor: not-allowed;
		}

		&[data-error="true"] {
			border-color: $brand-error;
		}
	}

	&_check {
		position: absolute;
		inset: calc(var(--checkbox-size) * 0.1667);
		width: var(--checkbox-size);
		height: var(--checkbox-size);
		color: var(--background);
		transform: scale(0.75) rotate(-90deg);
		transform-origin: center;
		opacity: 0;
		transition-property: transform, opacity;
		transition-duration: var(--transition-duration);
		transition-timing-function: var(--transition-timing-function);
		will-change: transform;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
	}

	&:hover,
	&:focus {
		.sb_checkbox {
			&_element {
				border-width: calc(var(--checkbox-size) * 0.15);
			}
		}
	}

	&:active {
		.sb_checkbox_element {
			&_element {
				border-width: calc(var(--checkbox-size) * 0.2);
			}
		}
	}

	&[data-checked="true"] {
		.sb_checkbox {
			&_element {
				border-width: $border-width * 2;
				background: var(--active-color);
			}

			&_check {
				opacity: 1;
				transform: scale(1.25) rotate(0deg);
			}
		}
	}
}
</style>
