<template>
	<img
		v-bind="$attrs"
		src="/assets/img/corbion.jpg"
		class="sb_logo hidden select-none drag-none md:block"
		alt="Corbion Logo"
	/>
	<img
		v-bind="$attrs"
		src="/assets/icons/icon-192x192.png"
		class="sb_logo sb_logo--icon block select-none drag-none md:hidden"
		alt="Corbion icon"
	/>
</template>

<style lang="scss">
.sb_logo {
	width: 100%;
	height: 100%;
	object-fit: contain;

	@media print {
		display: block;
	}

	&--icon {
		display: block;
		aspect-ratio: 1;

		@include media(">=md") {
			display: none;
		}

		@media print {
			display: none;
		}
	}
}
</style>
