<template>
	<div>
		<PopupModal header-icon :header-text="'Share this tool'" @close="$emit('close')">
			<form v-if="!success" @submit.prevent="validateAndSendRegistration">
				<h2 class="sb_label_heading">E-mail preview</h2>
				<div class="sb_comment_preview u_margin-bottom-40">
					<p>
						Dear
						{{ registration.recipientName ? registration.recipientName : "[name]" }},
					</p>
					<p>
						{{ registration.senderName ? registration.senderName : "[name]" }}
						thought you might be interested in this page on the Corbion website.
					</p>
					<p>For more information, please visit clcm.corbion.com.</p>
					<p>Thank you,</p>
					<p>Corbion</p>
				</div>
				<label>
					Your name
					<span class="sb_input_required">*</span>
					<input v-model="registration.senderName" type="text" placeholder="Your name" />
				</label>
				<label>
					Your e-mail
					<span class="sb_input_required">*</span>
					<input v-model="registration.senderEmail" type="email" placeholder="Your email" />
				</label>
				<label>
					Recipient name
					<span class="sb_input_required">*</span>
					<input v-model="registration.recipientName" type="text" placeholder="Your name" />
				</label>
				<label>
					Recipient e-mail
					<span class="sb_input_required">*</span>
					<input v-model="registration.recipientEmail" type="email" placeholder="Your email" />
				</label>
				<label>
					Your message
					<textarea v-model="registration.comment" placeholder="Your message"></textarea>
				</label>
				<div v-show="error" class="s_error">
					<p>{{ error }}</p>
				</div>
				<button
					class="sb_button v_has-icon-right v_icon-faded v_brand-primary-lighter v_expand-on-small"
					type="submit"
				>
					<ButtonObject icon="icon_ui_caret-right"> Send </ButtonObject>
				</button>
			</form>
			<div v-if="success">
				<hr />
				<p>
					Thank you for sharing the Corbion®
					<em>Listeria</em> Control Model application.
				</p>
				<hr />
				<button class="sb_button v_brand-primary-lighter v_expand-on-small" @click="$emit('close')">
					<ButtonObject>Close</ButtonObject>
				</button>

				<div v-if="loading" class="sb_modal_loader">
					<Loader />
				</div>
			</div>
		</PopupModal>
	</div>
</template>

<script lang="ts">
import { mapActions } from "vuex";
import Loader from "@/components/objects/loader.vue";
import PopupModal from "@/components/objects/popup-modal.vue";

export default {
	name: "PopupModalInformColleague",
	components: {
		PopupModal,
		Loader,
	},
	emits: { close: null },
	data() {
		return {
			error: null as string | null,
			success: false,
			loading: false,
			registration: {
				senderName: null,
				senderEmail: null,
				recipientName: null,
				recipientEmail: null,
				comment: "",
			},
			oRegisterError: {
				senderName: "Please provide your name",
				senderEmail: "Please provide your e-mail",
				recipientName: "Please provide the recipient name",
				recipientEmail: "Please provide the recipient e-mail",
				comment: "Please provide a comment",
			},
		};
	},
	methods: {
		...mapActions("notification", ["informAColleague"]),
		async validateAndSendRegistration() {
			for (const key of Object.keys(this.oRegisterError)) {
				const typedKey = key as keyof typeof this.registration;

				if (!this.registration[typedKey]) {
					this.error = this.oRegisterError[typedKey];
					return false;
				}
			}
			this.error = null;
			await this.sendRegistration();
			return true;
		},
		async sendRegistration() {
			this.loading = true;
			try {
				await this.informAColleague({
					...this.registration,
				});
				this.success = true;
				this.loading = false;
			} catch (error: TODO) {
				console.dir(error);
				console.dir(error.response.data);
				throw Error(error.response.data || error.message);
			}
			this.loading = false;
		},
	},
};
</script>

<style lang="scss">
.sb_comment_preview {
	background: $brand-lightest-gray;
	border: solid 1px #eaeaea;
	border-radius: 0.3rem;
	padding: 2rem;
	color: #333;
}
</style>
